import React, {useState} from 'react';
import './Contact.css';

function Contact() {
    const [formData, setFormData] = useState ({
        fullName: '',
        email: '',
        message: '',
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData ({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // change this to handle the form submission
        console.log(formData);
    };

    return (
        <>
            <section className='contact' id='contact'>
                <div className='container'>
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <label htmlFor='fullname'>Full Name:</label>
                            <input
                                type='text'
                                id='fullname'
                                name='fullname'
                                autoComplete='name'
                                value={formData.fullName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='email'>Email:</label>
                            <input
                                type='email'
                                id='email'
                                name='email'
                                autoComplete='email'
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='message'>Message:</label>
                            <textarea
                                id='message'
                                name='message'
                                value={formData.message}
                                onChange={handleInputChange}
                                rows="4"
                                required
                            />
                        </div>

                        <button type='submit'>Submit</button>
                    </form>
                </div>
                
            </section>
            
        </>
    );
}

export default Contact;