import "./Services.css";

import webBuilding from '../../media/web-building.gif';
import mobileBuilding from '../../media/mobile-building.gif';

function Services() {
    return (
        <>
            <section className="services" id="services">
                <div className="container">
                    <div className="row">
                        

                        <div className="services-col">
                            <h2>Our Services</h2>
                            <h3>Web Development</h3>
                            <h4>Empower Your Online Presence</h4>
                            <p>
                                Our web development services are tailored to create stunning and highly functional websites. We leverage the latest web technologies and design principles to ensure that your online presence is not only visually appealing but also user-friendly and responsive across all devices. Whether it's a corporate website, e-commerce platform, or a custom web application, we've got you covered.
                            </p>

                            <p>
                                &#8226; Custom Website Development<br/>
                                &#8226; Responsive Web Design<br/>
                                &#8226; E-Commerce solutions<br/>
                                &#8226; Content Management Systems<br/>
                                &#8226; Database Integration<br/>
                            </p>
                        </div>

                        <div className="services-col">
                            <img src={webBuilding} className="services-img" alt="Building a website"/>
                        </div>

                        <div className="services-col">
                            <h3>App Development</h3>
                            <h4>Innovate with Mobile Apps</h4>
                            <p>
                                Mobile apps have become the cornerstone of modern business. Our app development services focus on turning your ideas into intuitive, feature-rich applications that captivate your audience. From iOS to Android, we have the expertise to develop mobile solutions that are scalable, secure, and designed to drive user engagement.
                            </p>

                            <p>
                                &#8226; iOS App Development<br/>
                                &#8226; Android App Development<br/>
                                &#8226; Cross-Platform App Development<br/>
                                &#8226; UI/UX Design for Mobile Apps<br/>
                                &#8226; App Maintenance and Support<br/>
                            </p>
                        </div>

                        <div className="services-col">
                            <img src={mobileBuilding} className="services-img" alt="Building a mobile app"/>
                        </div>

                        <div>
                            <h3>Your Vision, Our Expertise</h3>
                            <p>
                                At TechFusion Innovation, we understand that each project is unique. That's why we work closely with you to craft tailored solutions that align with your goals and objectives. Our team of experienced developers and designers is committed to delivering high-quality products that make a difference in your business.
                            </p>
                            <p>
                                Whether you're looking to revamp your website, launch a mobile app, or explore the possibilities of digital innovation, TechFusion Innovation is your trusted partner. Let's embark on a journey of transformation and success.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Services;