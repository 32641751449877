import "./Home.css";

function Home() {
    return (
        <>
            <section className="home" id="home">
                <div className="container">
                    <div className="row">
                        
                        <div className="hero-info hero-col">
                            <h1 className="hero-title">Does your company have the technology resources to market online?</h1>
                            <p className="hero-description">
                                We can help your company thrive online.
                            </p>
                            {/* <button className="hero-button">Learn More</button> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;