import React, {useEffect, useRef, useState} from 'react';
import NavBar from './NavBar';
import logo from '../../media/logo.png';
import './Header.css';

function Header () {
    const [headerFixed, setHeaderFixed] = useState(false);
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const headerRef = useRef();

    const headerClasses = `header ${headerFixed ? 'scroll-down' : ''} ${
        mobileNavOpen ? 'nav-open' : ''
    }`;

    useEffect(() => {
        window.addEventListener('scroll', e => {
            if (window.pageYOffset >= 2) setHeaderFixed(true);
            else setHeaderFixed(false);
        });
    }, [setHeaderFixed]);

    return (
        <>
            <header ref={headerRef} className={headerClasses}>
                <a href='#home' className='brand' onClick={() => setMobileNavOpen(false)}><img src={logo} alt="Hero Background" className='header-image'/></a>

                <NavBar mobileNavOpen={mobileNavOpen} setMobileNavOpen={setMobileNavOpen}/>
                <div className='mobile-nav-circle'></div>
            </header>
        </>
    );
}

export default Header;