import "./About.css";

function About() {
    return (
        <>
            <section className="about" id="about">
                <div className="container">
                <h2>About Us</h2>
                    <div className="row about-box">

                        <div className="about-col">
                            <div className="about-text">
                                <h3>Our Story</h3>
                                <p>We are a group of individuals who all have the same goal, which is to put our technological skills to use for companies.
                                    At TechFusion, we're more than just a technology company – we're visionaries, problem solvers, and trailblazers. Our 
                                    journey began with a simple idea: to harness the power of technology and innovation to transform businesses and elevate industries.
                                    <br/><br/>We specialize in crafting bespoke technological solutions tailored to your unique needs. Whether it's software development, 
                                    digital strategy, or cutting-edge AI solutions, our team of experts is dedicated to turning your ideas into reality.
                                </p>
                            </div>
                        </div>
                        
                        <div className="about-col">
                            <div className="about-text">
                                <h3>Why Choose Us</h3>
                                <ul>
                                    <li>
                                        <p><b>&#8226; Expertise:</b> Our team consists of top-tier professionals with a wealth of experience in the tech industry.</p> 
                                    </li>

                                    <li>
                                        <p><b>&#8226; Innovation:</b> We're always at the forefront of emerging technologies, ensuring you stay ahead of the curve.</p> 
                                    </li>

                                    <li>
                                        <p><b>&#8226; Results:</b> Our track record of successful projects speaks for itself, demonstrating our dedication to delivering tangible results.</p> 
                                    </li>

                                    <li>
                                        <p><b>&#8226; Client-Centric:</b> Your success is our priority. We work closely with you to understand your objectives and provide tailor-made solutions.</p> 
                                    </li>     
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;