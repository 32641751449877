import React from "react";
import './Footer.css';
import logo from '../../media/logo.png';

function Footer() {
    return (
        <>
            <section className="footer">
                <div className="container">
                    <div className="row border-bottom">
                        <div className="footer-column">
                            <div className="footer-logo">
                                <img src={logo} alt="Logo" />
                            </div>

                            <div className="footer-email">
                                <p>Email: techfusion@contact.com</p>
                            </div>
                        </div>

                        <div className="footer-column">
                            <div className="footer-shortcuts">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/about">About</a></li>
                                    <li><a href="/services">Services</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row center">
                        <div className="footer-copyright">
                        <p>Copyright @ TechFusion Innovation 2023 | All rights reserved</p>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;