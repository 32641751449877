import React from 'react';
import { GrClose, GrMenu } from 'react-icons/gr';
import './Header.css';

function NavBar({setMobileNavOpen}) {
    return (
        <> 
            <nav className='nav'>
                <ul className='nav-list'>
                    <li>
                        <a href='#home' className='nav-link' onClick={() => setMobileNavOpen(false)}>Home</a>
                    </li>
                    <li>
                        <a href='#about' className='nav-link' onClick={() => setMobileNavOpen(false)}>About</a>
                    </li>
                    <li>
                        <a href='#services' className='nav-link' onClick={() => setMobileNavOpen(false)}>Services</a>
                    </li>
                    <li>
                        <a href='#contact' className='nav-link' onClick={() => setMobileNavOpen(false)}>Contact</a>
                    </li>
                </ul>
            </nav>
            <button className="nav-mobile-btn" onClick={() => setMobileNavOpen(prev => !prev)}>
                <GrMenu className="mobile-icon mobile-icon-open" size="35" />
                <GrClose className="mobile-icon mobile-icon-close" size="35" />
            </button>
        </>
    );
}

export default NavBar;